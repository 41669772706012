import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import * as dotenv from 'dotenv';

const dotEnv = dotenv.config();
Sentry.init({
  dsn: "https://9c80e8d5ce5444c1b0be259f57361de9@o468653.ingest.sentry.io/5500708",
  environment: process.env.ENVIRONMENT !== null ? process.env.ENVIRONMENT : "staging"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
