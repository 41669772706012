import React from 'react';
import './App.css';
import './tailwind.css';
import TodoList from './TodoList';
import { BrowserRouter as Router, NavLink, Link, Switch, Route } from "react-router-dom";
import Tasks from './Tasks';
import TaskForm from './TaskForm';
import Users from './Users';
import Logs from './Logs';

interface State {
  menuHidden: Boolean
}

class App extends React.Component<{}, State> {
  host:any;

  constructor (props: {}) {
    super(props)
    this.state = {
      menuHidden: true,
    };
  }
   
  render = () => {
    let menuCss = 'top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto';
    if (this.state.menuHidden) {
      menuCss = 'hidden top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto'
    }

    return (
      <Router>
      <nav className="flex items-center bg-gray-800 p-3 flex-wrap">
        <a href="#" className="p-2 mr-4 inline-flex items-center">
          <span className="text-xl text-white font-bold tracking-wide">Planning praktijk</span>
        </a>
        <button
          className="text-white inline-flex p-3 hover:bg-gray-900 rounded lg:hidden ml-auto hover:text-white outline-none nav-toggler"
          data-target="#navigation" onClick={()=>{this.setState({menuHidden:!this.state.menuHidden})}}>
          <i className="fas fa-bars"></i>
        </button>
      <div className={menuCss} id="navigation">
        <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
          <NavLink onClick={()=>{this.setState({menuHidden:true})}} className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-400 items-center justify-center hover:bg-gray-900 hover:text-white" activeClassName="bg-gray-900 text-white" to="/" exact>Opdrachten</NavLink>
          <NavLink onClick={()=>{this.setState({menuHidden:true})}} className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-400 items-center justify-center hover:bg-gray-900 hover:text-white" activeClassName="bg-gray-900 text-white" to="/tasks" exact>Planning</NavLink>
          <NavLink onClick={()=>{this.setState({menuHidden:true})}} className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-400 items-center justify-center hover:bg-gray-900 hover:text-white" activeClassName="bg-gray-900 text-white" to="/users" exact>Gebruikers</NavLink>
          <NavLink onClick={()=>{this.setState({menuHidden:true})}} className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-400 items-center justify-center hover:bg-gray-900 hover:text-white" activeClassName="bg-gray-900 text-white" to="/events" exact>Logs</NavLink>
        </div>
      </div>
    </nav>
        <Switch>
          <Route exact path="/" component={TodoList}></Route>
          <Route exact path="/tasks" component={Tasks}></Route>
          <Route exact path="/tasks/:id/edit" component={TaskForm}></Route>
          <Route exact path="/tasks/add" component={TaskForm}></Route>
          <Route exact path="/users" component={Users}></Route>
          <Route exact path="/events" component={Logs}></Route>
        </Switch>
    </Router>

    );
  }
}

export default App;
