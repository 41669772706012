import React, { useState } from 'react';
import './App.css';
import './tailwind.css';
import Spinner from './Spinner';
import * as dotenv from "dotenv";
import { User } from './Entity/user';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

interface State{
    users: User[],
    loading: Boolean,
    selectedUser?: User,
    showPicker: Boolean
}

class Users extends React.Component<{}, State> {
  host:any;
    constructor (props: {}) {
        super(props);
        dotenv.config();
        this.host = process.env.REACT_APP_API_URL;
        this.state = ({loading: true, users: [], showPicker: false});
    }

    click = (user: User) => {
        this.setState({
            showPicker: true,
            selectedUser: user,
        })
    }

    selectEmoji = (emoji : any) => {
        if (this.state.selectedUser) {
            let user = this.state.selectedUser;
            user.emoji = emoji.native;
            const userId = this.state.selectedUser.id;
            let url = this.host + "/users/" +userId;
            console.log(url);
        
            fetch(
                url, {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(user)
            }).then(
              () => {
                this.setState({showPicker: false});
                this.componentDidMount();
              }
            )
            .catch(function(err) {
              console.log(err);
            });
        }
    }

    componentDidMount = () => {
        const that = this;
        fetch(this.host + '/users')
        .then(async function(response){
        return await response.json();
        })
        .then(async function(body){
        let listUsers: Array<User> = [];
        for (let entry of body) {
            const user: User = {id: entry.id, name: entry.name, emoji: entry.emoji};
            listUsers.push(user);
        }
        that.setState({
            users: listUsers,
            loading: false
        });
        })
        .catch(function(err) {
        // Error 
        });  
    }

    render = () => {
        return (  
            <div className="max-w-md mx-auto mb-10">
                <div className="container space-y-8 min-w-full">
                    <span className="text-center m-2 text-2xl"> 
                        <h3 className="text-center text-blue-900">Gebruikers</h3>
                    </span>
                    <div className="p-6 pb-0 shadow-lg rounded-md bg-white hover:shadow-xl overflow-hidden">
                        <div className="flex justify-center">
                            <Spinner show={this.state.loading} />
                        </div>
                        <div className="flex-col">
                            {this.state.users.map((user) => 
                                <div className="">
                                    <div className="flex pb-4">
                                        <div className="flex-1 p-2 text-lg">{user.name}</div>
                                        <div className="flex-1 p-2 text-lg">
                                            {user.emoji}
                                            <a href="#" onClick={() => this.click(user)}><i className="pl-4 far fa-edit text-m"></i></a>
                                        </div> 
                                    </div>    
                                    {this.state.showPicker && user.id === this.state?.selectedUser?.id ? <div className="p-2 block"> <Picker set='apple' onSelect={this.selectEmoji} /> </div> : null}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    } 
}

export default Users;
