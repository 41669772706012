import React from 'react';
import { Todo } from './Entity/todo';

type Props = {
    todo: Todo,
    onComplete: any
}

type State = {
    todo: Todo
}

export default class TodoItem extends React.Component<Props,State>  {
    constructor(props: Props) {
        super(props);
        this.state = {
            todo: this.props.todo
        }
    }

    checked = (e: any) => {
        const todo = this.props.todo;
        this.props.onComplete(todo);
    }

    isCompleted = (): boolean => {
        return this.props.todo.completed ? true : false;   
    }

    render = () => {
        const textColor: String = this.isCompleted() ? 'text-gray-400' : 'text-blue-800';
        const lineThrough = this.isCompleted() ? 'line-through' : '';
        const titleClassName = "text-lg inline-block mr-4 " + textColor + ' ' + lineThrough;
        const locationClassName = "m-2 inline-block " + lineThrough;
        const backgroundColor = this.isCompleted() ? 'bg-gray-200' : 'bg-gray-400';
        const containerClassName = 'm-4 pl-3 shadow-md mb-4 rounded-md ' + backgroundColor;
        let user = null;
        if (this.props.todo.user) {
            user = <div className="ml-10 inline-block"><p>{this.props.todo.user.name}</p></div>
        }

        var checkbox = this.state.todo.completed ? 
            <input className="bg-white border border-gray-800 mr-2" type="checkbox" checked={this.state.todo.completed} value={this.props.todo.id}/> :
            <input className="bg-white border border-gray-800 mr-2" type="checkbox" checked={this.state.todo.completed} onChange={this.checked} value={this.props.todo.id}/>;
        return (
            <div className={containerClassName}>
              {!this.state.todo.disabled ? checkbox : ''}
              <h2 className={titleClassName}>{this.props.todo.title}</h2>
              <div className="inline-block">
                    <div className="text-green-800">
                        <div className={locationClassName}><i className="fas fa-tooth mr-2"></i>{this.props.todo.location}</div>
                    </div>
              </div>
              {user}
            </div>
        );
    }
}
