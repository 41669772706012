import * as dotenv from 'dotenv';
import Task from '../Entity/task';
import { Category } from '../Entity/category';
import { User } from '../Entity/user';
import Event from '../Entity/event';

export class ApiService {
    host:string;

    constructor() {
        dotenv.config();
        if (!process.env.REACT_APP_API_URL) {
            throw 'API URL is not set;'
        }
        this.host = process.env.REACT_APP_API_URL
    }

    getTaskById(id:string): Promise<Task> {
      return this.getResource(this.host + '/tasks/' + id)
        .then(resource => {
          return {
            id: resource.id,
            title: resource.title,
            frequency: resource.frequency,
            location: resource.location,
            day: resource.weekday,
            category: {
              id: resource.category.id,
              title: resource.category.title
            }
          }
        }
      );
    }

    async getTodos(date: string = '', limit:number = 10, offset:number = 0): Promise<[Task[], number]> {
      let host = `${this.host}/tasks`;
      if (date) {
        host += `?date=${date}`;
      }
      return this.getResource(host)
        .then(resources => {
          const resourcesMap = resources["data"].map((resource:any) => {
            let event;
            if (resource.event) {
              event = {
                id: resource.event.id,
                user: {
                  id: resource.event.user.id,
                  name: resource.event.user.name,
                  emoji: resource.event.user.emoji
                }
              }
            }
            return {
              id: resource.id,
              title: resource.title,
              frequency: resource.frequency,
              location: resource.location,
              day: resource.weekday,
              completed: resource.completed,
              category: {
                id: resource.category.id,
                title: resource.category.title
              },
              event: event
            }
          })
          return [resourcesMap, resources[1]];
        })
    }

    async getTasks(date: string = '', limit:number = 5, offset:number = 0): Promise<[Task[], number]> {
      let host = `${this.host}/tasks`;

      var params = new Map<string,string>();
      if (date) {
        params.set('date', date);;
      }

      if (limit) {
        params.set('limit',limit.toString());
      }

      if (offset !== null) {
        params.set('offset',offset.toString());
      }

      return this.getResource(host.concat(this.concatQueryString(params)))
        .then(resources => {
          const resourcesMap = resources["data"].map((resource:any) => {
            return {
              id: resource.id,
              title: resource.title,
              frequency: resource.frequency,
              location: resource.location,
              day: resource.weekday,
              completed: resource.completed,
              category: {
                id: resource.category.id,
                title: resource.category.title
              }
            }
          })
          return [resourcesMap, resources["count"]];
        })
    }

    updateTask(task:Task): boolean {
      this.postResource(`${this.host}/tasks/${task.id}/update`, task, "PATCH")
      return true;
    }

    async createTask(task:Task) {
      return await this.postResource(`${this.host}/tasks`, task, "POST");
    }

    async getCategories(): Promise<Category[]> {
      return this.getResource(`${this.host}/category`)
      .then(resources => {
        const categories: Category[] = resources.map((resource: { id: string; title: string; }) => {
          return {
            id: resource.id,
            title: resource.title
          }
        });
        return categories;
      })
    }

    getUsers(): Promise<User[]>{
      return this.getResource(`${this.host}/users`)
        .then(async function(body){
          let listUsers: Array<User> = [];
          for (let entry of body) {
            const user: User = {id: entry.id, name: entry.name + ' ' + entry.emoji, emoji: entry.emoji};
            listUsers.push(user);
          }
          return listUsers;
      })
    }

    getEvents(limit:number = 10, offset: number = 0): Promise<[Event[], number]> {
      var params = new Map<string,string>();
      if (limit) {
        params.set('limit',limit.toString());
      }

      if (offset !== null) {
        params.set('offset',offset.toString());
      }
      const querystring = this.concatQueryString(params);

      return this.getResource(`${this.host}/event`.concat(querystring))
        .then(async function(body){
          let events: Array<Event> = [];
          for (let entry of body["data"]) {
            const event: Event = {id: entry.id, user: entry.user, task: entry.task, completedAt: entry.completedAt};
            events.push(event);
          }
          return [events, body['count']];
        })
    }

    private async postResource(url: string, resource: object, method: string = 'POST') {
      return await fetch(url, {
        method: method,
        headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
        body: JSON.stringify(resource)
      }).catch(function(err) {
        throw new Error("Couldn't create the task");
      });
    }
 
    private async getResource(url: string) {
      return await fetch(url)
      .then(response => {
        return response.json();
      }).catch(err => {
        throw err;
      });
    }

    private concatQueryString(params: Map<string,string>) {
      var querystring:string = '';
      var count = 0;
      params.forEach((value,key) => {
        var delimiter = count > 0 ? "&" : "?";
        querystring = querystring.concat(delimiter, key, "=", value);
        count++;
      })
      return querystring;
    }
}