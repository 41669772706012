import React from 'react';
import './App.css';
import './tailwind.css';
import { Link, useLocation } from 'react-router-dom';

import { ApiService } from './Service/ApiService';
import Event from './Entity/event';
import Moment from 'moment';

interface State {
  events: Event[],
  page?: number,
  resultsPerPage: number,
  offset: number,
  totalCount?: number
}

class Logs extends React.Component<any, State> {
  apiService:ApiService;

  constructor (props: any) {
    super(props)
    this.apiService = new ApiService;
    this.state = {events: [], resultsPerPage: 10, offset: 0, page: 1}
  }

  async componentDidMount() {   
    const result = await this.apiService.getEvents(this.state.resultsPerPage, this.state.offset);
    console.log(result);
    this.setState({events: result[0], totalCount: result[1]})
  }

  async changePage(page: number){
    const offset = (page * this.state.resultsPerPage) - this.state.resultsPerPage;
    const result = await this.apiService.getEvents(this.state.resultsPerPage, offset);
    this.setState({events: result[0], totalCount: result[1], page: page})
  }
   
  render = () => {
    const paginationRow = []; 
    let tempTotal = 0
    let page = 1;
    
    if (this.state.totalCount) {
      do {
        const pageIndex = page;
        let link;
        if (this.state.page === pageIndex) {
          link = <a href="#" className="text-white  bg-gray-600 p-2">{page}</a>;
        } else {
          link = <a href="#" onClick={(e) => {this.changePage(pageIndex)}} className="hover:text-white hover:bg-gray-500 p-2">{page}</a>;
        }
        paginationRow.push(link);
        tempTotal += this.state.resultsPerPage;
        page++;
      }
      while (tempTotal < this.state.totalCount);
    }

    return (
      <div className="max-w-4xl mx-auto mb-5">
          <div className="container min-w-full">
            <span className="text-center m-2 text-2xl"> 
              <h3 className="text-center text-blue-900">Logs</h3>
            </span>
            <div className="p-2 pb-0 shadow-lg rounded-md bg-white hover:shadow-xl overflow-hidden">
              <table className="table-auto w-full leading-normal">
                <thead className="font-semibold text-gray-600 bg-gray-200">
                  <tr className="hidden md:table-row">
                    <th className="table-cell text-left p-4">
                      <p>Omschrijving</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Praktijk</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Voltooid op</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Uitgevoerd door</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Categorie</p>
                    </th>
                    <th className="table-cell">&nbsp;</th>
                  </tr>
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {this.state.events.map((event) => {               
                    return <tr className="flex p-2 hover:bg-gray-100 md:table-row flex-col flex-no-wrap border-b">
                      <td className="p-4 table-cell">    
                        {event.task.title}
                      </td>
                      <td className="p-4 table-cell">    
                        {event.task.location}
                      </td>
                      <td className="p-4 table-cell">    
                        { Moment(event.completedAt).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-4 table-cell">    
                        {event.user.name}
                      </td>
                      <td className="p-4 table-cell">    
                        {event.task.category?.title}
                      </td>
                    </tr>;
                  })}
                </tbody>
              </table>
              <div className="flex p-4 ">
                {paginationRow}
              </div>
            </div>
          </div>  
        </div>
    );
  }
}

export default Logs;
