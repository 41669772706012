import React from 'react';
import './App.css';
import './tailwind.css';
import TodoItem from './TodoItem';
import Modal from './Modal';
import Moment from 'moment';
import Spinner from './Spinner';
import * as dotenv from "dotenv";
import { ApiService } from './Service/ApiService';
import { User } from './Entity/user';
import { Todo } from './Entity/todo';

interface State{
  loading: boolean
  completedTodoList: Todo[],
  unCompletedTodoList: any[]
  modalOpen: boolean,
  activeTodo: Todo | null,
  users: User[],
  currentDate: any
}

class TodoList extends React.Component<{}, State> {
  host:any;
  apiService:ApiService;

  constructor (props: {}) {
    super(props)
    const moment = Moment();

    this.state = {
      loading: true,
      unCompletedTodoList: [],
      completedTodoList: [],
      modalOpen: false,
      activeTodo: null,
      users: [],
      currentDate: moment
    }
    this.completeTodo = this.completeTodo.bind(this);
    this.openModal = this.openModal.bind(this);
    this.apiService = new ApiService();
    dotenv.config();
    this.host = process.env.REACT_APP_API_URL
  }

  findTodo = (id:string) => {
    return this.state.unCompletedTodoList.find(todo => todo.id === id);
  }

  openModal = (todo: Todo) => {
    console.log(todo);
    this.setState({
      modalOpen: true,
      activeTodo: todo
    });
  }

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  previousDay = () => {    
    const previousDay = this.state.currentDate.subtract(1, 'days')
    this.setState({
      loading: true,
      currentDate: previousDay
    })
    this.setState({completedTodoList: []});
    this.setState({unCompletedTodoList: []});
    this.fetchData(previousDay.format("YYYY-MM-DD")); 
  }

  nextDay = () => {
    const nextDay = this.state.currentDate.add(1, 'days')
    this.setState({
      loading: true,
      currentDate: nextDay
    })
    this.setState({completedTodoList: []});
    this.setState({unCompletedTodoList: []});
    this.fetchData(nextDay.format("YYYY-MM-DD")); 
  }

  toDay = () => {
    const today = Moment();
    this.setState({
      loading: true,
      currentDate: today
    })
    this.fetchData(today.format("YYYY-MM-DD")); 
  }
  
  completeTodo = (completedTodo: Todo): void => {
    let unCompletedTodoList = this.state.unCompletedTodoList.filter(todo => todo.id !== completedTodo.id);
    let completedList = this.state.completedTodoList;
    completedList.push(completedTodo);
    this.setState({
      unCompletedTodoList: unCompletedTodoList,
      completedTodoList: completedList,
      modalOpen: false
    });

    const event = {
      completedAt: Moment().format("YYYY-MM-DD hh:mm:ss"),
      userId: completedTodo.user?.id
    }

    fetch(this.host + '/tasks/' + completedTodo.id + '/events' , {
      method: 'POST',
      headers: {'Content-Type': 'application/json;charset=utf-8'},
      body: JSON.stringify(event)
    })
      .then(async function(response){
        return await response.json();
      })
      .catch(function(err) {
        // Error 
      });
  }

  async componentDidMount() {
    this.fetchData(this.state.currentDate.format("YYYY-MM-DD"));
  }

  async fetchData(date:string) {
    const that = this;
    const disabled = Moment().isBefore(date);

    const tasks = await this.apiService.getTodos(date);
    
    let uncompletedList: Array<Todo> = [];
    let completedList: Array<Todo> = [];
    const tasksMap = tasks[0];
    tasksMap.map((task) => {
      if (!task.completed) {
        uncompletedList.push({id: task.id, title: task.title, location: task.location, completed: false, disabled: disabled});
        this.setState({unCompletedTodoList: uncompletedList});
      } else if (task.completed) {
        console.log(task.event?.user);
        if (task.event?.user) {
          const name = task.event?.user.name + ' ' + task.event.user.emoji;
          const user: User = {id: task.event.user.id, name:name, emoji: task.event.user.emoji};
          const todo: Todo = {id: task.id, title: task.title, location: task.location, completed: task.completed, user: user, disabled: disabled};
          completedList.push(todo);
          this.setState({completedTodoList: completedList});
        }
      }
    })
  
    const users = await this.apiService.getUsers();
    this.setState({users: users, loading:false});
  }
   
  render = () => {
    let modal = null;
    if (this.state.activeTodo !== null && this.state.modalOpen) {
      modal = <Modal todo={this.state!.activeTodo} users={this.state.users} onClose={this.closeModal} onOk={this.completeTodo} />;
    }

    var weekday = '';
    const day = this.state.currentDate.format('dddd');
    switch (day) {  
        case 'Monday':
          weekday = 'Maandag';
          break;
        case 'Tuesday':
          weekday = 'Dinsdag';
          break;
        case 'Wednesday':
          weekday = 'Woensdag'
          break;
        case 'Thursday':
          weekday = 'Donderdag'
          break;
        case 'Friday':
          weekday = 'Vrijdag';
          break;
        case 'Saturday':
          weekday = 'Zaterdag'
          break;
        case 'Sunday':
          weekday = 'Zondag'
          break;
    }

    return (  
      <div className="max-w-2xl mx-auto">
        <div className="mt-10 pb-2 shadow-lg rounded-md bg-white ">
            <span className="flex justify-center flex-wrap items-baseline">
              <span className="text-center text-2xl p-8">  
                <button>
                    <i className="fas fa-arrow-left text-blue-800" onClick={this.previousDay}></i>
                </button>
                </span>
                <span className="p-4">
                  <h3 className="text-center text-blue-900">{weekday}</h3>
                  <h1 className="text-2xl text-center text-blue-800">
                      {this.state.currentDate.format("DD/MM/YYYY")}
                  </h1>
                </span>
                <span className="text-center p-8 text-2xl">
                <button>
                    <i className="fas fa-arrow-right text-blue-800" onClick={this.nextDay}></i>
                </button>
              </span>
              <span className="p-4">
                <button onClick={this.toDay} className="hover:bg-gray-100 py-2 px-4 rounded border border-gray-400 " >Vandaag</button>
              </span>
          </span>
          <div className="flex justify-center">
            <Spinner show={this.state.loading} />
          </div>
          {this.state.unCompletedTodoList.length > 0 ? <h2 className="text-1xl mb-5 ml-5 text-blue-800">To do:</h2> : null }
          {this.state.unCompletedTodoList.length === 0 && !this.state.loading ?
          <div><h1 className="text-2xl pb-5 text-center text-green-500">Alle taken zijn volbracht! 🎉</h1>
          <div className="flex justify-center">
            <img className="w-40" src="undraw_refreshing_beverage.svg" />
            </div></div> : null }
          {this.state.unCompletedTodoList.map((todo) => <TodoItem todo={todo} onComplete={this.openModal} />)}
          <div className="mt-10">
            {this.state.completedTodoList.length > 0 ? <h2 className="text-1xl mb-5 ml-5 text-blue-800">Volbracht:</h2> : '' }
            {this.state.completedTodoList.map((todo) => <TodoItem todo={todo} onComplete={this.openModal} />)}
          </div>
        </div> 
      {modal}
    </div>
    );
  }
}

export default TodoList;
