import React from 'react';

interface Props {
    label: string;
    options: {value: string, name: string}[],
    selected: string,
    onSelect: any,
    isDisabled: boolean
}

class SelectForm extends React.Component<Props> {
    constructor (props: any) {
        super(props)
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(value: string) {
        this.props.onSelect(value);
    }

    render = () => {
        if (!this.props.isDisabled) {
            return (<div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
                {this.props.label}
                </label>
            </div>
            <div className="inline-block relative md:w-2/3 w-full">
                <select
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                onChange={(
                    ev: React.ChangeEvent<HTMLSelectElement>,
                ): void => this.handleSelect(ev.target.value)}
                value={this.props.selected}
                >
                {this.props.options.map((option: {value: string, name: string}) => <option value={option.value}>{option.name}</option>)}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>
            </div>);
        } else {
            return (<div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
                <label className="block text-gray-200 font-bold md:text-right mb-1 md:mb-0 pr-4" >
                {this.props.label}
                </label>
            </div>
            <div className="inline-block relative md:w-2/3 w-full text-gray-300">
                <select disabled
                className="block appearance-none w-full bg-gray border border-gray-100 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                onChange={(
                    ev: React.ChangeEvent<HTMLSelectElement>,
                ): void => this.handleSelect(ev.target.value)}
                value={this.props.selected}
                >
                {this.props.options.map((option: {value: string, name: string}) => <option className="text-gray-200" value={option.value}>{option.name}</option>)}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-200">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>
            </div>);
        }
    }
}

export default SelectForm;
