import React from 'react';
import './App.css';
import './tailwind.css';
import { Link, Redirect } from 'react-router-dom';
import Spinner from './Spinner';
import { ApiService } from './Service/ApiService';
import Task from './Entity/task';
import { Category } from './Entity/category';
import SelectForm from './SelectForm';

interface State {
  task: Task
  categories?: Category[]
  redirect: boolean
  showSpinner: boolean
  daysEnabled: boolean
}

class TaskForm extends React.Component<{}, State> {
  apiService: ApiService;

  constructor (props: any) {
    super(props)
    this.apiService = new ApiService();

    this.handleDay = this.handleDay.bind(this);
    
    const taskId = props.match.params.id

    this.state = {
      task: {
        title: '',
        frequency: 'Weekly',
        location: 'Praktijk 1', 
        id: taskId ? taskId : null,
      },
      redirect: false,
      showSpinner: false,
      daysEnabled: true
    };
  }

  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    let response;

    this.setState({showSpinner: true});
    if (this.state.task && this.state.task.id) {
      if (this.apiService.updateTask(this.state.task)) {
        this.setState({redirect: true})
      }
    } else {
      response = this.apiService.createTask(this.state.task);
      if ((await response).ok) {
        this.setState({redirect: true})
      } else {
        // TODO: Handle the error properly 
      }
      this.setState({showSpinner: true});
    }
  };

  private handleName(value : any): void {
    const task = this.state.task;
    task.title = value;
    this.setState({ task: task});
  }

  private handleDay(value : any): void {
    const task = this.state.task;
    task.day = value;
    this.setState({ task: task });
  }

  private handleFrequency(value: any): void {
    const task = this.state.task;
    task.frequency = value;
    if (value === 'Daily') {
      task.day = '';
      this.setState({daysEnabled: false});
    }
    this.setState({ task: task });
  }

  private handleLocation(value: any): void {
    const task = this.state.task;
    task.location = value;
    this.setState({ task: task });
  }

  private handleCategory(value: any): void {
    const task = this.state.task;
    task.categoryId = value
    this.setState({ task: task });
  }

  async componentDidMount() {
    const categories = await this.apiService.getCategories();
    if (this.state.task && this.state.task.id) {
      const task: Task = await this.apiService.getTaskById(this.state.task.id);
      task.categoryId = task.category?.id;
      this.setState({
        task:task
      })
      if (task.frequency === 'Daily') {
        task.day = "";
        this.setState({daysEnabled: false});
      }
    } else {
      const task = this.state.task;
      task.categoryId = categories[0].id;
      this.setState({task:task})
    }
    this.setState({categories: categories});
  }

  getDaysList(): {value: string, name: string}[] {
    return [
      {value: '', name: '- Selecteer een dag -'},
      {value: 'Monday', name: 'Maandag'},
      {value: 'Tuesday', name: 'Dinsdag'},
      {value: 'Wednesday', name: 'Woensdag'},
      {value: 'Thursday', name: 'Donderdag'},
      {value: 'Friday', name: 'Vrijdag'},
      {value: 'Saturday', name: 'Zaterdag'},
      {value: 'Sunday', name: 'Zondag'}
    ]
  }

  render = () => {
    if (this.state.redirect) {
      return <Redirect to="/tasks?success" />
    }
    
    return (
      <div className="max-w-lg mx-auto">
        <span>
          <span className="text-center m-8 text-2xl"> 
          <h3 className="text-center text-blue-900">Planning: {this.state.task.id ? 'Opdracht bewerken' : 'Opdracht Toevoegen'}</h3>
          </span>
        </span>

        <div className="p-10 pt-5 shadow-lg rounded-md bg-white">
        <div className="">
          <div className="flex flex-row pb-10 text-gray-700 text-sm">
            <Link className="mr-2 hover:gray-800" to="/tasks">Planning</Link>
            <p className="mr-2">&rarr;</p>
            <p>{this.state.task.id ? 'Opdracht bewerken' : 'Opdracht Toevoegen'}</p>
          </div>
        </div>
          <div className="items-center"> 
            {this.state.showSpinner ? <Spinner show={true} /> : null}
            <form className="w-full" method="POST" onSubmit={this.handleSubmit}>
                <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
                        Naam
                        </label>
                    </div>
                    <div className="md:w-2/3">
                        <input 
                          onChange={(
                              ev: React.ChangeEvent<HTMLInputElement>,
                          ): void => this.handleName(ev.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" 
                          type="text" 
                          value={this.state.task.title} 
                          />
                    </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
                        Frequentie
                        </label>
                    </div>
                    <div className="inline-block relative md:w-2/3 w-full">
                      <select 
                          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          onChange={(
                            ev: React.ChangeEvent<HTMLSelectElement>,
                          ): void => this.handleFrequency(ev.target.value)}
                          value={this.state.task.frequency}
                        >
                        <option value="Daily">Dagelijks</option>
                        <option value="Weekly">Wekelijks</option>
                        <option value="Monthly">Maandelijks</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                </div>
                <SelectForm label="Dag" options={this.getDaysList()} selected={this.state.task.day ? this.state.task.day : '' } onSelect={this.handleDay} isDisabled={!this.state.daysEnabled} />
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                      <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
                        Praktijk
                      </label>
                  </div>
                  <div className="inline-block relative md:w-2/3 w-full">
                    <select 
                      className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                      onChange={(
                        ev: React.ChangeEvent<HTMLSelectElement>,
                      ): void => this.handleLocation(ev.target.value)}
                      value={this.state.task.location}>
                      <option value="Praktijk 1">Praktijk 1</option>
                      <option value="Praktijk 2">Praktijk 2</option>
                      <option value="Praktijk 3">Balie</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                      <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
                        Categorie
                      </label>
                  </div>
                  <div className="inline-block relative md:w-2/3 w-full">
                    <select 
                      className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                      onChange={(
                        ev: React.ChangeEvent<HTMLSelectElement>,
                      ): void => this.handleCategory(ev.target.value)}
                      value={this.state.task.categoryId}>
                      {this.state.categories?.map((category: Category) => 
                        <option value={category.id}>{category.title}</option>
                      )}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>
                
                <div className="flex flex-row-reverse justify-between">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Bewaar
                  </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskForm;
