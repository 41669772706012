import React from 'react';
import { Todo } from './Entity/todo';
import { User } from './Entity/user';

type Props = {
    onClose: any
    onOk: any,
    todo: Todo,
    users: User[]
}

type State = {
    user: User
}

export default class Modal extends React.Component<Props,State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            user: props.users[0]
        };
    }

    onClose = () => {
        this.props.onClose();
    }

    onOk = (e: any) => {
        const {id, title, location} = this.props.todo;
        const user: User = this.state.user;
        const todo: Todo = {
            id: id,
            title: title,
            location: location,
            completed: true,
            user: user,
            disabled: true
        }
        this.props.onOk(todo);
    }

    handleChange = (e: any) => {
        const user = this.props.users.find((user) => user.id == e.target.value) ;
        if (user) {
            this.setState({
                user: user
            })
        }
    }

    render = () => {
            return (
            <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
                <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">                                
                    <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                        <p className="text-2xl font-bold">{this.props.todo!.title} - {this.props.todo!.location} </p>
                            <div className="modal-close cursor-pointer z-50" onClick={this.onClose}>
                                <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                </svg>
                            </div>
                        </div>
                
                        <div className="w-full">
                        <div className="relative">
                            <label className="tracking-wide text-gray-700 font-bold mb-2">
                                Uitgevoerd door:
                            </label>
                            <select value={this.state.user.id} onChange={this.handleChange} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                {this.props.users.map((user) => <option value={user.id}>{user.name}</option>)}                      
                            </select>    
                            <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                 <svg className="fill-current mt-4 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        </div>
                
                        <div className="flex justify-end pt-2">
                            <button className="modal-close px-4 bg-red-700 p-3 rounded-lg text-white hover:bg-red-400" onClick={this.onClose}>Annuleer</button>
                            <button className="ml-2 modal-close px-4 bg-green-500 p-3 rounded-lg text-white hover:bg-green-400" value={this.props.todo!.id} onClick={this.onOk}>Voltooi</button>
                        </div>
                    </div>
                </div>    
            </div>    
        );
    }

}
