import React from 'react';
import './App.css';
import './tailwind.css';
import { Link, useLocation } from 'react-router-dom';
import Alert from './Alert';
import Task from './Entity/task';
import { ApiService } from './Service/ApiService';

interface State {
  tasks: Task[],
  showAlert: boolean,
  alertMessage: string,
  page?: number,
  resultsPerPage: number,
  offset: number,
  totalCount?: number
}

class Tasks extends React.Component<any, State> {
  apiService:ApiService;

  constructor (props: any) {
    super(props)

    this.apiService = new ApiService;

    let showAlert = false;
    let alertMessage = '';
    if (props.location.search === '?success') {
      showAlert = true;
      alertMessage = 'Opdracht is succesvol toegevoegd'
    }

    this.state = {tasks: [], showAlert: showAlert, alertMessage: alertMessage, resultsPerPage: 10, offset: 0, page: 1}
  }

  async componentDidMount() {   
    const result = await this.apiService.getTasks('', this.state.resultsPerPage, this.state.offset);
    this.setState({tasks: result[0], totalCount: result[1]})
  }

  async changePage(page: number){
    const offset = (page * this.state.resultsPerPage) - this.state.resultsPerPage;
    console.log(offset);
    const result = await this.apiService.getTasks('', this.state.resultsPerPage, offset);
    this.setState({tasks: result[0], totalCount: result[1], page: page})
  }

  translate(value: string): any {
    let translatesMap = [
      ['Monday', 'Maandag'], ['Tuesday', 'Dinsdag'], ['Wednesday', 'Woensdag'], ['Thursday', 'Donderdag'], ['Friday', 'Vrijdag'], ['Saturday', 'Zaterdag'], ['Sunday', 'Zondag'],
      ['Weekly', 'Wekelijks'], ['Monthly', 'Maandelijks'],  ['Daily', 'Dagelijks']
    ];
    let translation = translatesMap.find((element) => {return (element[0] === value)});
    if (translation) {
      return translation[1];
    }
    return value;
  }

  renderTableCell (colIndex: number, value?:string, ) {
    return <td className="p-4 table-cell">
      {value ? this.translate(value) : ''}
    </td>
  }

  renderTableRow (task: Task) {
    return <tr className="flex p-2 hover:bg-gray-100 md:table-row flex-col flex-no-wrap border-b">
        {[task.title, task.location, task.day, task.frequency, task.category?.title].map((value, index) => {return this.renderTableCell((index+1), value)})}
        <td className="p-4 table-cell">    
          <Link to={'/tasks/' + task.id + '/edit'}><i className="fas fa-edit mr-2"></i></Link>
        </td>
        {/* <td headers="th6" className="p-4 table-cell">
          <Link to={'/tasks/' + task.id + '/events'}><i className="far fa-file-alt mr-2"></i></Link>
        </td> */}
      </tr>;
  }
   
  render = () => {
    const paginationRow = []; 
    let tempTotal = 0
    let page = 1;
    
    if (this.state.totalCount) {
      do {
        const pageIndex = page;
        let link;
        if (this.state.page === pageIndex) {
          link = <a href="#" className="text-white  bg-gray-600 p-2">{page}</a>;
        } else {
          link = <a href="#" onClick={(e) => {this.changePage(pageIndex)}} className="hover:text-white hover:bg-gray-500 p-2">{page}</a>;
        }
        paginationRow.push(link);
        tempTotal += this.state.resultsPerPage;
        page++;
      }
      while (tempTotal < this.state.totalCount);
    }

    return (
      <div className="max-w-4xl mx-auto mb-10">
          <div className="container space-y-8 min-w-full">
            <span className="text-center m-2 text-2xl"> 
              <h3 className="text-center text-blue-900">Planning</h3>
            </span>
            {this.state.showAlert ? <Alert message={this.state.alertMessage}/> : null}
            <div className="p-2 pb-0 shadow-lg rounded-md bg-white hover:shadow-xl overflow-hidden">
              <div className="flex flex-row-reverse justify-between p-4">
                <Link 
                  to='/tasks/add'
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                  Taak Toevoegen
                </Link>
              </div>

              <table className="table-auto w-full leading-normal">
                <thead className="font-semibold text-gray-600 bg-gray-200">
                  <tr className="hidden md:table-row">
                    <th className="table-cell text-left p-4">
                      <p>Omschrijving</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Praktijk</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Dag</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Frequentie</p>
                    </th>
                    <th className="table-cell text-left p-4">
                      <p>Categorie</p>
                    </th>
                    <th className="table-cell">&nbsp;	</th>
                  </tr>
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {this.state.tasks.map((task) => { return this.renderTableRow(task)} )}
                </tbody>
              </table>
              <div className="flex p-4 ">
                {paginationRow}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Tasks;
